<template>
    <p class="instructions">
Hello Joss,
<br/>
<br/>
I want to play a game.
<br/>
<br/>
Todo este tiempo has creído saber que he preparado para ti. En este momento veremos si has acertado.
La escena del crimen en Málaga 45 tiene una trampa, que solo se puede deshabilitar con la llave que está en mi poder.
<br/>
<br/>
En esta caja encontrarás un cubo de Rubik que en su interior contiene las direcciones de mi ubicación. Encuentrame allí y podremos continuar el juego.
<br/>
<br/>
Tienes hasta las 18:45 para llegar a la locación. Y veremos si puedes resolver el misterio que se te presenta antes de 
que corra más sangre inocente.
<br/><br/>

Live or die. Make your choice.
<br/>
</p>
<img src="@/assets/obsidiannahual.jpeg" class="logo">
</template>

<script setup>

</script>
