import { createApp } from 'vue'
import App from './App.vue'
import { createWebHistory, createRouter } from 'vue-router'

import {NotFound, HomeView, MicroondasView, AgustinView, CuadroView, CamaView, PuzzleView, LavadoraView, ClosetView, BDayView} from './views'


const routes = [
  { path: '/', component: HomeView },
  { path: '/IXopD4cQ0J', component: AgustinView },
  { path: '/wWLGTPUThx', component: CuadroView },
  { path: '/0GTS8MC9X7', component: MicroondasView },
  { path: '/bpaTH5KnE8', component: CamaView },
  { path: '/mUBnhbhHtb', component: PuzzleView },
  { path: '/PuQl2b31Sr', component: LavadoraView },
  { path: '/KuFMc80HKn', component: ClosetView },
  { path: '/Lq2Jq1750M', component: BDayView },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})


createApp(App).use(router).mount('#app')
