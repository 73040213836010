<template>
  <RiddleComponent @playing="()=>{playing=true}" @stopping="()=>{playing=false}" >
    Mientras los vecinos seguían con su rutina diaria, Beatriz estaba a punto de morir. Fue un caso que estremeció a toda una ciudad y dejó un sinfín de preguntas sin respuestas. ¿Cómo llegó su cabeza al microondas? ¿Quién haría algo tan cruel? Y la más importante: ¿cómo escapó el responsable sin ser visto por la policía?
<br><br>
Los detectives habían registrado el departamento una y otra vez, buscando alguna pista que pudiera esclarecer la línea temporal. Revisaron cada rincón, inspeccionaron muebles y tomaron muestras de todo. Pero había un lugar que aún no habían explorado completamente, una zona que había pasado desapercibida durante toda la investigación: debajo de la cama.
<br><br>
Fue entonces cuando surgió una teoría intrigante. ¿Y si el autor del crimen nunca hubiera escapado del departamento?
  </RiddleComponent>
  <div v-show="playing">
      <ClueComponent  :clues="clues" :solution="solution"></ClueComponent>
  </div>
  
</template>

<script setup>
import {ref} from "vue"
import RiddleComponent from "../components/RiddleComponent"
import ClueComponent from "../components/ClueComponent"
const playing = ref(false)

const clues = [
  "Investiga los rumores",
  "¿Habrá alguien ahí?",
]
const solution = "Bajo la cama"
</script>
