<template>
    <div class='center'>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/_V4JGACBWRM?si=bJlmQw3ZepKq0X96" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>
   
</template>

<script setup>

</script>

<style>
  .center {
display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 50vh;
}
</style>
