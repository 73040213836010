<template>
  <RiddleComponent @playing="()=>{playing=true}" @stopping="()=>{playing=false}" >
    <h1>Nota del detective</h1>
    <blockquote>
      “Uno nunca espera encontrar jabón para ropa en una escena de crimen tan sangrienta. A primera vista, parece insignificante, una pieza que no encaja. Pero, con los años en este trabajo, he aprendido que no hay detalles menores, no cuando se trata de entender qué pasó realmente.
<br><br>
Lo curioso es que el jabón no estaba derramado. Estaba allí, colocado con cuidado en la cocina, cerca del microondas. No parecía algo olvidado por accidente. Fue dejado para que lo encontráramos, casi como si el responsable quisiera hacernos saber que, aunque trató de ‘limpiar’ su rastro, algo quedó detrás. Como si, a su manera, buscara redimirse… o quizás burlarse de nosotros, de nuestra habilidad para conectar los puntos.”
    </blockquote>
  </RiddleComponent>
  <div v-show="playing">
      <ClueComponent  :clues="clues" :solution="solution"></ClueComponent>
  </div>
  
</template>

<script setup>
import {ref} from "vue"
import RiddleComponent from "../components/RiddleComponent"
import ClueComponent from "../components/ClueComponent"
const playing = ref(false)

const clues = [
  "¿De dónde salió el jabón?",
  "¿Dónde lo usaba Beatriz?",
  "¿Podría ser afuera?",
  "¿En el cuarto de lavado?",
]
const solution = "Lavadora"
</script>
