<template>
  <RiddleComponent @playing="()=>{playing=true}" @stopping="()=>{playing=false}" >
    <div class='center'>
    <iframe src="https://www.youtube.com/embed/GMY4fMZsxEI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>
  </RiddleComponent>
  <div v-show="playing">
      <ClueComponent  :clues="clues" :solution="solution"></ClueComponent>
  </div>
  
</template>

<script setup>
import {ref} from "vue"
import RiddleComponent from "../components/RiddleComponent"
import ClueComponent from "../components/ClueComponent"
const playing = ref(false)

const clues = [
  "Dentro de mí caben conejos y ranas.",
  "Si termino mi quehacer, grito."
]
const solution = "El microondas"
</script>

<style>
  .center {
display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 40vh;
}
</style>
