<template>
  <RiddleComponent @playing="()=>{playing=true}" @stopping="()=>{playing=false}" >
    <h1>Nota del detective</h1>
    <blockquote>
      “Cada caso es un rompecabezas… pero nunca un rompecabezas limpio, nunca el tipo en el que tienes todas las piezas a la vista, esperando pacientemente a que las pongas en su lugar. Aquí, algunas piezas están manchadas de glitter, otras escondidas entre sombras, y otras, simplemente, faltan. Mi trabajo es encontrar esas piezas y hacer que encajen, aunque a veces me lleve al borde de la locura.”
    </blockquote>
  </RiddleComponent>
  <div v-show="playing">
      <ClueComponent  :clues="clues" :solution="solution"></ClueComponent>
  </div>
  
</template>

<script setup>
import {ref} from "vue"
import RiddleComponent from "../components/RiddleComponent"
import ClueComponent from "../components/ClueComponent"
const playing = ref(false)

const clues = [
  "Lo más simple es a veces correcto.",
  "¿Dónde se guardan los rompecabezas?",
]
const solution = "Mueble de la entrada"
</script>
