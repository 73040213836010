<template>
    <div v-if="playback" class="riddle-container"><slot></slot></div>
    <div class="recorder">
        <button @click.prevent="togglePlayback(true)" :disabled="playback"
            class='mediaButton'>
            <img src="@/assets/icons8-play-64.png"/>
        </button>
        <button @click.prevent="()=>{playback=false}" class='mediaButton' :disabled="!playback"><img src="@/assets/icons8-pause-64.png"/></button>
        <button @click.prevent="togglePlayback(false)" class='mediaButton' :disabled="!playback"><img src="@/assets/icons8-stop-64.png"/></button>
        <button @click.stop disabled class='mediaButton'><img src="@/assets/icons8-rewind-64.png"/></button>
        <button @click.stop disabled class='mediaButton redTint'><img class="redTint" src="@/assets/icons8-record-64.png"/></button>
    </div>
</template>

<script setup>
import {ref} from "vue"
// eslint-disable-next-line
const emit = defineEmits(["playing", "stopping"])
const playback = ref(false)
function togglePlayback(value){
    playback.value = value
    if(value){
        emit("playing")
    }
    else{
        emit("stopping")
    }
}

</script>

<style>
.riddle-container {
    font-size: large;
    text-align: justify;
}
.mediaButton {
  border: 2px outset ButtonFace;
  color: GrayText;
  cursor: inherit;
  background-color: #ddd;
  background: #ddd;
  img {
    opacity: 1;
    max-width:100%;
    height:auto;
  }
}

.mediaButton[disabled]:active, .mediaButton[disabled],
.mediaButton[disabled]:hover
{
  border: 2px outset ButtonFace;
  color: GrayText;
  cursor: inherit;
  background-color: #ddd;
  background: #ddd;
  img {
    opacity: 0.7;

  }
}


.redTint .redTint[disabled]:active, .redTint[disabled],
.redTint[disabled]:hover {
    background-color: #8f0e0e;
    background: #8f0e0e;
    border: 2px outset #8f0e0e;
    
}
.recorder
{
position:fixed;
text-align:center;
width:100%;
bottom:0%;
padding:0%;
margin:0%;

vertical-align:center;

/* Mobile Devices */
@media (max-width: 480px) {
    left:0%;
    .mediaButton[disabled]:active, .mediaButton[disabled],
    .mediaButton[disabled]:hover, .mediaButton {
        width: 20%;
    }
}

}

</style>

