<template>
  <RiddleComponent @playing="()=>{playing=true}" @stopping="()=>{playing=false}" >
    Para otra pista hallar, <br>
en la entrada del hogar<br>
algo no está en su lugar.<br>
  </RiddleComponent>
  <div v-show="playing">
      <ClueComponent  :clues="clues" :solution="solution"></ClueComponent>
  </div>
  
</template>

<script setup>
import {ref} from "vue"
import RiddleComponent from "../components/RiddleComponent"
import ClueComponent from "../components/ClueComponent"
const playing = ref(false)

const clues = [
  "No hay que moverse mucho",
  "Algo en las paredes no está bien",
]
const solution = "Cuadro"
</script>
