<template>
  <RiddleComponent @playing="()=>{playing=true}" @stopping="()=>{playing=false}" >
    Si a Beatriz quieres ver,
con atención debes leer:
<br>
<br>
- El beso es como la metamorfosis de Ovidio -dijo el pensador-. Abre puertas, hasta la puerta del infierno. Si no me crees, deberías ir y preguntarle directamente al creador.
<br>
<br>
Y al 385-A dirigirte.
  </RiddleComponent>
  <div v-show="playing">
      <ClueComponent  :clues="clues" :solution="solution"></ClueComponent>
  </div>
  
</template>

<script setup>
import {ref} from "vue"
import RiddleComponent from "../components/RiddleComponent"
import ClueComponent from "../components/ClueComponent"
const playing = ref(false)

const clues = [
  "Los sinsentidos usualmente contienen un código",
  "Existe una correlación entre los actores de la oración",
  "A veces los autores son importantes.",
  "El grito es una pintura de Edvard Munch.",
  "El pensador, La puerta del infierno, El beso, La metamorfosis de Ovidio",
]
const solution = "Augusto Rodin 385-A"
</script>
