<template>
  <RiddleComponent @playing="()=>{playing=true}" @stopping="()=>{playing=false}" >
    <h1>Monólogo del detective</h1>
    <blockquote>
      Todo comenzó con detalles que no cuadraban. La escena, a primera vista, parecía vacía, abandonada con prisa, pero había algo en el desorden que me resultaba… calculado. El cuadro cambiado, pertenencias escondidas bajo la cama, las cosas del cuarto de lavado. Parecía demasiado perfecto, demasiado intencional, como si alguien hubiera querido distraerme del fondo del departamento.
      <br><br>
      Revisé una y otra vez las fotos de la escena del crimen. Sabía que algo no estaba bien. Entonces, mi mirada vuelve a la fotografía del clóset. Todo me alejaba de allí desde el principio, y sólo cuando las piezas encajan veo la verdad: alguien… o algo está adentro, apenas asomándose por entre los vestidos.
      <br><br>
      Y hoy, la imagen de esa puerta entreabierta cuenta una historia… una historia que en su momento dejé pasar.
      <br><br>
      El responsable permaneció escondido ahí todo ese tiempo. Y aún hoy, al regresar al departamento, puedo imaginármelo asomando su cuerpo deforme buscando la manera de salirse con la suya.
      <br><br>
    </blockquote>
  </RiddleComponent>
  <div v-show="playing">
      <ClueComponent  :clues="clues" :solution="solution"></ClueComponent>
  </div>
  
</template>

<script setup>
import {ref} from "vue"
import RiddleComponent from "../components/RiddleComponent"
import ClueComponent from "../components/ClueComponent"
const playing = ref(false)

const clues = Array(7).fill("Oh no.")
const solution = "Clóset drag"
</script>
