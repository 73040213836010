<template>
  <h1>PLAY ME</h1>
  <hr/>
  <main>
    <RouterView />
  </main>
</template>

<script setup>
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lacquer&display=swap');
body {
  font-family: "Lacquer", system-ui;
  font-weight: 400;
  font-style: normal;
	font-size: x-large;
	background: whitesmoke;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #590000;
  margin: 0%;
  padding: 0%;
  height: 100%;
  width: 100%;
}
hr {
  background-color: whitesmoke;
  padding: 0;
  margin-top: 0;
  margin-bottom: 2em;
  border: 0;
  height: 0;
  box-shadow: 0 0 10px 1px black;
}

hr:after {
  content: "\00a0";
}
#app {
  font-family: "Lacquer", system-ui;
  font-weight: 400;
  font-style: normal;
	font-size: x-large;
	background: whitesmoke;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #590000;
  margin: 0%;
  margin-bottom: 25%;
  height: 100%;
  width: 100%;
}
.instructions {
    font-size: medium;
    text-align:justify;
}
.logo {
 display: inline-block;
 text-align: center;
 heigth: auto;
 width: 20vh;
}

</style>
